input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.bridge-page {
    background-image: url(./space.png);
}

.exchange {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    position: relative;
}

.exchange-body {
    width: 454px;
    position: relative;
}

.exchange-element {
    padding: 30px 20px;
    border: 1px solid #2B2B2B;
    border-radius: 40px;
    background: rgba(28, 28, 35, 0.6);
    margin-bottom: 10px;
}

.exchange-element-body {
    position: relative;
}

.exchange-element-titles {
    font-weight: 400;
    font-size: 14px;
    color: #F1F1F180;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.coin-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.base-coin {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
}

.base-coin span {
    margin-left: 5px;
}

.buttons button {
    color: #FFFFFF80;
    font-size: 14px;
    border-radius: 24px;
    margin-left: 5px;
    padding: 5px 20px 5px 20px;
    line-height: 21px;
    gap: 10px;
    border: 1px solid #2B2B2B;
    background: linear-gradient(to left,rgba(28, 28, 35, 0.6), rgba(0, 0, 0, 0.6));
}

.buttons button:hover {
    box-shadow: 0 0 16.7px rgba(255, 255, 255, 0.35);
}

.exchange-input {
    margin-top: 10px;
    position: relative;
}

.dropdown {
    color: rgba(241, 241, 241, 1);
    font-size: 14px;
    display: inline-block;
    max-width: 106px;
}

.dropdown-input-area:first-child {
    position: absolute;
    left: 15px;
    top: 15px;  
}
.dropdown-area{
    position: relative;
    z-index: 4;
    height: 35px;
}


.currnet-coin {
    padding: 7px 10px 7px 10px;
    border: 1px solid rgba(43, 43, 43, 1);
    border-radius: 30px;
    display: flex;
    height: 35px;
    box-sizing: border-box;
    align-items: center;
    background: rgba(28, 28, 35, 0.6);
    cursor: pointer;
}

.currnet-coin img:first-of-type{
    margin-right: 4px;
}

.currnet-coin img:last-of-type {
    margin-left: 8px;
}

.dropdown-options {
    width: 200px;
    border-radius: 20px;
    background: linear-gradient(to left,rgba(28, 28, 35, 0.9), rgba(0, 0, 0, 0.9));
}

.closed {
    display: none;
}

.exchange-input input{
    height: 65px;
    width: 100%;
    background: rgba(10, 10, 10, 1);
    color: rgba(241, 241, 241, 1);
}

.exchange-input input[type=number]{
    direction: rtl;
    font-size: 30px;
}

.exchange-input input[type=text] {
    font-size: 14px;
    direction: ltr;
}

.exchange-input input[type=text]::placeholder {
    color: rgba(241, 241, 241, 0.15);
}

.dropdown-element {
    display: flex;
    align-items: center;
    padding: 0px 15px 0px 15px;
    height: 40px;
    cursor: pointer;
}

.dropdown-element span {
    margin-left: 4px;
}

.dropdown-element:hover {
    border-radius: 20px;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(28, 28, 35, 1));

}

.bridge-button {
    margin-top: 10px;
}

.bridge-button button {
    border-radius: 71px;
    width: 100%;
    padding: 20px 35px 20px 35px;
    background: linear-gradient(to bottom, rgba(71, 188, 252, 1), rgba(7, 143, 209, 1));
    border: none;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: rgba(241, 241, 241, 1);

}


.exchange-icon {
    width: 46px;
    position: absolute;
    left: 227px;
    top:178px;
    transform: translate(-50%, 10%);
    background: linear-gradient(to bottom, rgba(71, 188, 252, 1), rgba(7, 143, 209, 1));
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: 0 0 16.7px rgba(71, 188, 252, 0.58);

}